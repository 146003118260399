<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview p-3">
                             <div class="toggleTab text-center">
                                <ul class="customTab d-inline-flex justify-content-center align-items-center">
                                    <li><a href="#">Exports</a></li>
                                    <li><a class="active" href="#">Imports</a></li>
                                </ul>
                            </div>
                            <div class="toggleTab text-center">
                                <ul class="customTab d-inline-flex justify-content-center align-items-center">
                                    <li><a href="#">Category Wise</a></li>
                                    <li><a class="active" href="#">Country Wise</a></li>
                                </ul>
                            </div>
                            <div class="showTab-one d-none">
                                <div class="charIQ text-center my-3"><img src="assets/images/map17.png" alt="map" /></div>
                                <ul class="listCountry dot mb-0">
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> Mineral, Fuels, Oils, Products</span> 31% </a>
                                    </li>
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> Machine, Nuclear, Boilers</span> 28% </a>
                                    </li>
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> Pharmaceuticals</span> 12% </a>
                                    </li>
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> Precious, Stones, metals, Coin</span> 20% </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="showTab-two">
                                <div class="charIQ text-center my-3"><img src="assets/images/map18.png" alt="map" /></div>
                                <ul class="listCountry dot mb-0">
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> United States</span> 51% </a>
                                    </li>
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> Hong Kong</span> 10% </a>
                                    </li>
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> UAE</span> 19% </a>
                                    </li>
                                    <li>
                                        <a href="#" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center"> China</span> 20% </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="mainOverview p-3">
                            <h4 class="green mb-3 text-center">Forex & Trade</h4>
                            
                            <div id="forexTradeChart" style="width: 100%; height: 500px"></div>
                            <ul class="listCountry dot mb-0" v-if="percentData.length > 0">
                                <li v-for="item,index in percentData.slice(0,6)" :key="index">
                                    <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center"> <span class="d-flex align-items-center">{{item.Title}}</span>{{item.LatestValue}}{{(item.Unit == 'percent') ? '%' : item.Unit}}</a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap">
                            <economic-mainnavbar></economic-mainnavbar>
                            <economic-map v-show="Object.keys(store.economicGDPData).length > 0"></economic-map>
                            <!-- <carousel  :settings="settings" :breakpoints="breakpoints">
                                 <slide v-for="item,index in otherdata.slice(0,otherdata.length-1)" :key="index">
                                    <div class="d-flex align-items-center">
                                        <span class="flex-shrink-0 me-2 iconRate"><img src="assets/images/exchange-rate.svg" alt="Generic image" /></span>
                                        <div class="flex-grow-1">
                                            <span class="iconcolor f-13">{{item.Title}}</span>
                                            <h3 class="m-0">{{item.LatestValue}}{{(item.Unit == 'percent') ? '%' : item.Unit}}</h3>
                                        </div>
                                    </div>
                                 </slide>
                                 <template #addons>
                                    <Navigation />
                                </template>
                            </carousel> -->
                        </div>
                    </div><!-- 
                    <div class="col-12">
                        <bottom-navbar></bottom-navbar>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import economicmainnavbar from "./files/economy_mainnavbar";
import economicmap from "./files/economy_map";
// import bottomnavbar from "./files/bottom_navbar";
import economicsearch from "./files/economy_search";
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import * as am5 from "@amcharts/amcharts5";
import * as index from "@amcharts/amcharts5/index";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Carousel, Slide, Navigation,  } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    setup() {
    const store = useMainStore();
    return { store };
  },
    data() {
        return {
            percentData : [],
            pointsData : [],
            otherdata : [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                767: {
                  itemsToShow: 2,
                  snapAlign: 'center',
                },
                1024: {
                  itemsToShow: 3,
                  snapAlign: 'start',
                },
                1199: {
                  itemsToShow: 5,
                  snapAlign: 'start',
                },
            },
        }
    },
    // watch:{
    //     'store.economicGDPData'(){
    //         this.drawforexTradeChart()
    //     }
    // },
    components: {
        bannerSlider:bannerslider,
        economicMap:economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch:economicsearch,
        economicMainnavbar:economicmainnavbar,
         Carousel,
        Slide,
        Navigation,

    }, 
    methods:{
        
        callCountryEconomyData(country){
            let formData ={
                "country" : country,
                "group" : ['Trade']
            }
          this.store
            .getCountryEconomyData(formData, true)
            .then((response) => {
            })
            .catch((err) => console.log(err.message));

        }
    },   
    mounted(){
        if (this.$route.query.country) {
          this.callCountryEconomyData(this.$route.query.country);
        }
        // if(Object.keys(this.store.economicGDPData).length > 0){ 
        //     am5.ready(() => {
        //         this.drawCompositionChart();
        //     });
        // }
    } 
}
</script>
